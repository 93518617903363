.post-type-container .header {
  padding: 20px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}
.post-type-container .header p {
  font-weight: bold;
}
.post-type-container .header .select {
  display: flex;
  gap: 10px;
}
.post-type-container .header .select .icon {
  border: 1px solid #e3e4e8;
  color: #929292;
  padding: 1px 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-left: 10px;
}
.post-type-container .stackBarChart_container {
  background-color: white;
  padding: 20px;
  display: flex;
  text-align: center;
  margin: 0 !important;
  border-radius: 4px;
  min-height: 400px;
}
.stackBarChart_container p {
  font-weight: bold;
}
.post-type-container .curcle_div {
  padding: 20px;
  display: flex;
  gap: 30px;
  background-color: white;
}
.post-type-container .curcle_div div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.post-type-container .curcle_div .story-content {
  background-color: rgba(0, 0, 0, 0.842);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.post-type-container .curcle_div .feed-content {
  background-color: #ef5b29f3;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.post-type-container .curcle_div .stream-content {
  background-color: #ef5b29bd;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
@media screen and (max-width: 425px) {
  .post-type-container .header {
    flex-direction: column;
    gap: 10px;
  }
  .post-type-container .header div {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .post-type-container .curcle_div {
    flex-direction: column;
  }
}
