.campaign-email-confirmation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
}

.campaign-email-confirmation-container .company-logo {
  height: 60px;
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.campaign-email-confirmation-container .main {
  min-height: 60vh;
  width: 600px;
  padding: 2rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.campaign-email-confirmation-container .main .flow-container {
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
  margin: 2.5rem 0;
}
.campaign-email-confirmation-container .main .flow-container img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.campaign-email-confirmation-container .main .flow-container .admin-logo{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.campaign-email-confirmation-container .main .table-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 0.5rem;
  margin: 2rem 0;
}
.campaign-email-confirmation-container .main .table-container .title {
  color: var(--primary);
}
.campaign-email-confirmation-container .main .content-table {
  width: 100%;
  text-align: left;
  font-size: 18px;
}
.campaign-email-confirmation-container .main .content-table .table-header {
  line-height: 50px;
}
.campaign-email-confirmation-container .main .content-table tr {
  line-height: 40px;
}
.campaign-email-confirmation-container .main .hash-tag-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.5rem;
}
.campaign-email-confirmation-container .main .hash-tag-container .tag {
  width: max-content;
  font-size: 14px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  border: 1px solid #e4e2e2;
  padding: 5px 15px;
  border-radius: 22px;
}
.campaign-email-confirmation-container .main .hash-tag-container .title {
  color: var(--primary);
}
.campaign-email-confirmation-container .main .hash-tags {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 6px;
}
.campaign-email-confirmation-container .main h2 {
  font-size: 30px;
  color: var(--primary);
  font-weight: 600;
  width: 100%;
  margin-bottom: 1rem;
}
.campaign-email-confirmation-container .main h3 {
  font-size: 20px;
  font-weight: 600;
  width: 100%;
}
.campaign-email-confirmation-container .main p {
  font-size: 20px;
  font-weight: 600;
  width: 100%;
}
.campaign-email-confirmation-container .main button {
  width: 50% !important;
  margin-top: 2rem;
}

.campaign-email-confirmation-container .status-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.campaign-email-confirmation-container .status-div .icon {
  font-size: 90px;
  color: #19f576;
}
.campaign-email-confirmation-container .invalid .icon {
  font-size: 70px;
  color: #e74c3c;
}
.campaign-email-confirmation-container .status-div p {
  font-size: 20px;
  font-weight: 600;
}

.campaign-email-confirmation-container .btns-container {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.campaign-email-confirmation-container .btns-container .reject-btn {
  border-radius: 5px !important;
  font-weight: bold !important;
  letter-spacing: 2px !important;
  height: 40px !important;
  background-color: transparent !important;
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
}

.campaign-email-confirmation-container .btns-container .reject-btn:hover {
  background-color: var(--primary) !important;
  color: #fff !important;
}
.confirmed-box, .reject-box{
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  background-color: var(--primary);
  margin-top: 1.5rem;
  width: 100%;
  border-radius: 10px;
  padding: 10px 0;
  color: white !important;
}

.confirmed-box p, .reject-box p{
  width: max-content !important;
}
.confirmed-box svg{
  width: 25px !important;
  height: 25px !important;
}
.reject-box svg{
  width: 25px !important;
  height: 25px !important;
}

@media (max-width: 900px) {
  .campaign-email-confirmation-container img {
    height: 40px;
    top: 1rem;
    left: 1rem;
  }
  .campaign-email-confirmation-container .main h2 {
    font-size: 20px;
    margin-bottom: 0.5rem;
  }
  .campaign-email-confirmation-container .main h3 {
    font-size: 15px;
  }
  .campaign-email-confirmation-container .main p {
    font-size: 15px;
  }
  .campaign-email-confirmation-container .main button {
    width: 150px;
    margin-top: 1rem;
    height: 34px;
  }
  .campaign-email-confirmation-container .btns-container .reject-btn {
    height: 34px !important;
  }
  .campaign-email-confirmation-container .main .content-table {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .campaign-email-confirmation-container {
    background-color: white;
  }
  .campaign-email-confirmation-container .main {
    min-height: 30vh;
    width: 100%;
    box-shadow: unset;
    padding: 1rem;
  }
  .campaign-email-confirmation-container img {
    height: 30px;
    top: 1rem;
    left: 1rem;
  }
}
