.v-layout {
  padding: 2rem;
}
.pub-v-layout{
  padding: 2rem;
}
.ant-layout.ant-layout-has-sider {
  background-color: transparent !important;
}
.ant-menu-dark .ant-menu-sub {
  background: white !important;
}

.ant-layout-sider::-webkit-scrollbar {
  /* display: none; */
}

.ant-switch-checked .ant-switch-inner {
  margin-left: 3px !important;
}

.v-layout .company-logo {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 10vh;
  margin-bottom: 10px;
  margin-top: 1rem;
  background-color: transparent;
}
.v-layout .company-logo img {
  width: 90%;
}
.v-layout .company-title {
  font-size: 22px;
  color: var(--text) !important;
  text-transform: uppercase;
}
.v-layout .collapse-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 0.5rem;
}

.v-layout .collapse-div .trigger {
  background-color: var(--primary);
  color: white;
  width: 36px;
  height: 30px;
  padding: 4px;
  padding-right: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  cursor: pointer;
}
.ant-layout {
  background-color: transparent !important;
}
.ant-drawer-content-wrapper {
  width: 600px !important;
}
@media (max-width: 650px) {
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}
