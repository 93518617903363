.heat-map-container {
  background-color: white;
}
.heat-map-container .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  padding-bottom: 0;
}
.heat-map-container .header h3 {
  font-size: 15px;
  font-weight: 600;
  font-family: "avenir";
}
.heat-map-container .header div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.heat-map-container .ant-table-tbody {
  text-align: center !important   ;
}
.heat-map-container .ant-table-cell:first-child {
  padding: unset !important;
}
.heat-map-container .ant-table-cell {
  text-align: center !important;
}
.heat-map-container .profile {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 40px 0 10px;
  height: 54px;
}
.heat-map-container .profile p {
  max-width: 120px;
  font-size: 12px;
  font-weight: 600;
  font-family: "avenir";
}
.heat-map-container .profile h3 {
  border: 1px solid #f0f0f0;
  padding-right: 70px;
}
.heat-map-container .profile img {
  width: 30px;
  height: 30px;
  border-radius: 100px;
}
