.bb-card {
  background-color: white;
  border-radius: 8px;
  max-width: 427px;
  cursor: pointer;
}
.bb-card:hover{
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}
.bb-card .header {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  gap: 1rem;
  padding: 16px;
  padding-bottom: 2rem;
}

.bb-card .header .profile {
  position: relative;
  width: 60px;
  height: 60px;
}

.bb-card .header .profile .img {
  width: 100%;
  height: 60px;
  border-radius: 10%;
}
.bb-card .header .profile .icon {
  position: absolute;
  bottom: -6px;
  right: -12px;
  background-color: blanchedalmond;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 4px;
  color: rgb(121, 121, 121);
  cursor: pointer;
  border: 1px solid blanchedalmond;
}
.bb-card .header .profile .icon:hover{
  border: 1px solid rgb(207, 192, 168);
  color: rgb(180, 163, 135);
}

.bb-card .header .info {
  display: flex;
  flex-direction: column;
  padding-left: 4px;
}
.bb-card .header .info .name {
  font-size: 15px;
  font-weight: 600;
}
.bb-card .header .info .name:hover{
  text-decoration: underline;
}
.bb-card .header .info .desc {
  font-size: 12px;
  font-weight: 600;
  color: rgb(175, 175, 175);
}

.bb-card .media {
  width: 100%;
  height: 250px;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
}
.bb-card:hover .media .more {
  width: 100%;
  height: 250px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.233);
  z-index: 4;
}
.bb-card .media .image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.bb-card .media .icon {
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: rgba(235, 235, 235, 0.733);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  color: var(--primary);
  left: 1rem;
  top: 10px;
}

.bb-card .detail {
  padding: 16px;
  font-size: 14px;
  border-bottom: 2px solid rgb(218, 218, 218);
  color: gray;
  height: 100px;
  overflow: auto;
}
.bb-card .detail::-webkit-scrollbar{
  display: none;
}

.bb-card .engage-container {
  padding: 20px 20px;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap !important;
}

.bb-card .engage-container .engage-div .tooltip {
  display: flex !important;
  align-items: center !important;
  gap: 6px !important;
  cursor: pointer;
}
.bb-card .engage-container .engage-div .tooltip span {
  color: gray;
  font-size: 16px;
}
.bb-card .engage-container .engage-div .tooltip .icon {
  font-size: 20px !important;
  margin-bottom: 4px;
  color: gray;
}
