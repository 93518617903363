.influencer-container .network-row-card {
  height: max-content;
  background-color: white;
  border-radius: 10px;
  cursor: unset;
}
.influencer-container .network-row-card .left-side {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.influencer-container .network-row-card .left-side .image-div {
  position: relative;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  cursor: pointer;
}
.influencer-container .network-row-card .left-side .image-div img {
  width: 100%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  height: 300px;
}
.influencer-container .network-row-card .left-side .image-div .options {
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 10px;
  left: 10px;
  display: none;
}
.influencer-container .network-row-card:hover .left-side .image-div .options {
  display: flex;
}
.influencer-container .network-row-card .left-side .image-div .options .icon {
  height: 32px;
  width: 32px;
  padding: 7px;
  border-radius: 4px;
  background-color: white;
  color: rgb(99, 99, 99);
  cursor: pointer;
}
.influencer-container .network-row-card .left-side .image-div .options .icon:hover {
  color: var(--primary);
  background-color: rgb(241, 241, 241);
}

.influencer-container .network-row-card .middle {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-left: 2rem;
}
.influencer-container .network-row-card .middle .social {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}
.influencer-container .network-row-card .middle .social .icon {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  padding: 6px;
  color: rgb(121, 121, 121);
  cursor: pointer;
  background-color: blanchedalmond;
}
.influencer-container .network-row-card .middle .social .disable {
  background-color: rgba(180, 180, 180, 0.212);
  color: rgb(204, 204, 204);
  cursor: default;
}

.influencer-container .network-row-card .middle .categories {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 6px;
}
.influencer-container .network-row-card .middle .categories span {
  padding: 2px 7px;
  border-radius: 5px;
  font-size: 11px;
  font-weight: 600;
  color: #fc7548;
  border: 1px solid #fc7548;
}
.influencer-container .network-row-card .middle .address {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  margin-top: 1rem;
}
.influencer-container .network-row-card .middle .address .icon {
  color: gray;
  font-size: 18px;
  margin-left: -2px;
  margin-bottom: 6px;
}
.influencer-container .network-row-card .middle .campaign {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  margin-top: 6px;
}
.influencer-container .network-row-card .middle .campaign .icon {
  color: gray;
  font-size: 16px;
  margin-bottom: 4px;
}
.influencer-container .network-row-card .middle .campaign:hover .icon {
  color: var(--primary);
}
.influencer-container .network-row-card .middle .desc {
  width: 98%;
}
.influencer-container .network-row-card .middle .reach {
  color: white;
  background-color: var(--primary);
  padding: 4px 12px;
  font-size: 14px;
  height: max-content;
  border-radius: 4px;
  position: absolute;
  top: 16px;
  right: 10px;
}
.influencer-container .network-row-card .middle .facebook-connect {
  position: absolute;
  top: 16px;
  right: 10px;
}

.influencer-container .network-row-card .middle .facebook-connect .account {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2374f2;
  border-radius: 4px;
  color: white;
  width: max-content;
  padding: 6px 14px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'avenir';
  gap: 6px;
}

.influencer-container .network-row-card .right-side {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1rem;
  border-left: 3px solid rgb(219, 219, 219);
  padding-left: 0rem;
}
.influencer-container .network-row-card .right-side .list {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding-left: 2rem;
}
.influencer-container .network-row-card .right-side .list .icon {
  font-size: 18px;
  color: gray;
}
.influencer-container .network-row-card .right-side .list .count {
  /* margin-left: 2rem; */
  float: right;
}

.influencer-container .network-row-card .right-side .header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  padding-right: 1rem;
  margin-bottom: 1.3rem;
}

.influencer-container .network-row-card .right-side .header .icon {
  height: 32px;
  width: 32px;
  padding: 7px;
  border-radius: 4px;
  background-color: white;
  color: rgb(99, 99, 99);
  cursor: pointer;
  border: 1px solid rgb(214, 214, 214);
}
.influencer-container .network-row-card .right-side .header .icon:hover {
  color: var(--primary);
  background-color: rgb(241, 241, 241);
}

.influencer-container .tabs-container {
  margin-top: 1.4rem;
}

.influencer-container .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 20px !important;
}

.influencer-container .back-icon-container {
  background-color: white;
  border: 1px solid white;
  color: var(--primary);
  border-radius: 5px;
  width: max-content;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  font-size: 16px;
  cursor: pointer;
  margin: 0 0 1rem 0;
}

.influencer-container .back-icon-container:hover {
  background-color: var(--primary);
  border: 1px solid var(--primary);
  color: white;
}

.influencer-container .back-icon-container svg {
  width: 24px;
  height: 24px;
}

.social_performance_search{
  background-color: #FFFFFF !important;
}

@media (max-width: 700px) {
  .influencer-container .network-row-card .right-side {
    border: 0;
    padding-bottom: 1rem;
    padding-left: 1rem;
  }
  .influencer-container .network-row-card .middle {
    padding-left: 1rem;
  }
  .influencer-container .network-row-card .middle .social {
    margin-bottom: 6px;
  }
  .influencer-container .network-row-card .middle .social .icon {
    width: 24px;
    height: 24px;
    padding: 4px;
  }
  .influencer-container .network-row-card .middle .reach {
    padding: 4px 6px;
    font-size: 10px;
  }
  .influencer-container .network-row-card .left-side .image-div {
    border-bottom-left-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .influencer-container .network-row-card .left-side .image-div img {
    border-bottom-left-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .influencer-container .network-row-card .right-side {
    padding-top: 0;
  }
  .influencer-container .network-row-card .right-side .list {
    padding-left: 0;
  }
  .influencer-container .network-row-card .right-side .header {
    margin-bottom: 0;
  }
}
