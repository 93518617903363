.campaign-container .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.campaign-container .header .left h2 {
  font-size: 26px;
}

.campaign-container .header .left p {
  font-size: 12px;
  color: #444e5a;
  display: flex;
  align-items: center;
  gap: 6px;
}
.campaign-container .header .left p .icon {
  font-size: 14px;
}

.campaign-container .header .right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.campaign-container .header .right .share-btn {
  padding: 4px 20px !important;
  background-color: var(--primary) !important;
  color: white !important;
  border: 1px solid var(--primary) !important;
}
.campaign-container .header .right .share-btn:hover {
  color: var(--primary) !important;
  background-color: transparent !important;
}
.campaign-container .header .right .icon {
  height: 35px;
  width: 35px;
  border-radius: 6px;
  padding: 6px;
  background-color: white;
  color: gray;
  border: 1px solid rgb(202, 202, 202);
  cursor: pointer;
}
.campaign-container .header .right .icon:hover {
  color: var(--primary);
  border-color: var(--primary);
}
.campaign-container .header .right .spin-icon {
  height: 35px;
  width: 35px;
  border-radius: 6px;
  padding: 6px;
  background-color: white;
  color: var(--primary);
  border: 1px solid rgb(202, 202, 202);
  cursor: pointer;
}
.campaign-container .header .right .spin-icon:hover {
  border-color: var(--primary);
}

/* CampaignProfile */

.campaign-profile-container .steps {
  display: flex;
  overflow: auto;
}

.campaign-profile-container .steps .filter-card__item {
  background-color: #f6f6f8;
  border-radius: 6px;
  width: 30%;
  margin-right: 5px;
  padding: 24px 20px;
  position: relative;
  cursor: pointer;
}
.campaign-profile-container .steps .filter-card__item:hover {
  background-color: white;
}
.campaign-profile-container .steps .filter-card__item:hover::before {
  background-color: white !important;
}
.campaign-profile-container .steps .filter-card__item-num {
  display: block;
  font-size: 28px;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 10px;
}

.campaign-profile-container .steps .filter-card__item-label {
  display: block;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  word-break: break-word;
}
.campaign-profile-container .steps .filter-card__item11 {
  background-color: #f6f6f8;
  border-radius: 6px;
  width: 30%;
  margin-right: 5px;
  padding: 24px 20px;
  position: relative;
}
.campaign-profile-container .steps .filter-card__item11:hover {
  background-color: white;
}
.campaign-profile-container .steps .filter-card__item11:hover::before {
  background-color: white !important;
}
.campaign-profile-container .steps .filter-card__item::before {
  content: '';
  position: absolute;
  right: -16px;
  top: 56px;
  background-color: #f6f6f6;
  width: 25px;
  height: 26px;
  border-radius: 5px;
  border: 5px solid #e2e2e2;
  transform: translateZ(1px) rotate(40deg);
  z-index: 10;
  border-bottom: none;
  border-left: none;
}
.campaign-profile-container .steps .active,
.campaign-profile-container .steps .active::before {
  background-color: white !important;
}

.campaign-profile-container .profile-info {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.campaign-profile-container .profile-info img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.campaign-profile-container .searchInput {
  width: 200px;
}

.add-content-container {
  /* border: 1px solid rgb(233, 233, 233); */
  padding: 1rem 0;
  border-radius: 4px;
  margin: 2rem 0;
}

.add-content-container .bin {
  font-size: 34px;
  color: rgb(179, 179, 179);
  cursor: pointer;
}

.add-content-container .bin:hover {
  color: var(--primary);
}

.option {
  display: flex;
  align-items: center;
  gap: 6px;
}
.option-image {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
/* Campaign-cost-container-css */
.campaign-cost-container .table-container {
  margin-top: 1rem !important;
}
.campaign-cost-container .update-btn {
  padding: 4px 20px !important;
  background-color: transparent !important;
  color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}
.campaign-cost-container .update-btn:hover {
  color: white !important;
  background-color: var(--primary) !important;
}
/* Campaign-cost-container-css */

.custom-table-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-weight: 700;
}

/* .custom-cost-table-2 thead > tr > th{
  display: flex;
  width: 100%;
  height: 100%;
} */

.custom-cost-table .ant-table-footer {
  background-color: var(--primary) !important;
}

@media (max-width: 700px) {
  .campaign-profile-container .steps .filter-card__item {
    min-width: 120px;
  }
  .campaign-profile-container .steps .filter-card__item11 {
    min-width: 120px;
  }
}
