.influencer-card{
    background-color: white;
    border-radius: 8px;
    padding: 1rem 0;
    position: relative;
    border: 2px solid transparent;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.influencer-card-active{
    border: 2px solid var(--primary);
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.influencer-card .bottom{
    padding: 0 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.influencer-card .bottom p{
    word-break: normal;
}

.influencer-card .inf_cat_check{
    position: absolute;
    top: 0;
    left: 10px;
}