.chart_parent .Audience_div {
  background-color: white;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 20px;
  margin-bottom: 2rem;
}

.chart_parent .icon_div {
  border: 1px solid;
  border-radius: 5px;
  line-height: 0;
  cursor: pointer;
}

.chart_parent .icon_div > :not(:last-child) {
  border-right: 1px solid;
}

.chart_parent .social_icon {
  padding: 8px;
  font-size: 40px;
}

.chart_parent .social_icon:hover {
  background-color: black;
  color: white;
}

.ringChart_div {
  background-color: white;
  padding: 20px 20px 70px 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 27px;
}

.ringChart_div h3 {
  align-self: flex-start;
}

.ringChart_div .states_Div {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  border-top: 1px solid #f1f1f1;
}

.ringChart_div .g2-html-annotation {
  font-size: 25px !important;
}

.chart_parent .progress_bar {
  padding: 20px 20px 125px 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  gap: 30px;
  min-height: 390px;
}

.chart_parent .header {
  display: flex;
  gap: 10px;
}

.chart_parent .header .icon {
  border: 1px solid;
  font-size: 20px;
  border-radius: 4px;
  cursor: pointer;
}

.gander_icon {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #aa90fb;
}

.progress_bar .parcentage_div {
  display: flex;
  justify-content: space-between;
}

.chart_parent .mini_progress_bar {
  padding: 20px 20px 20px 20px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 390px;
}

.mini_progress_bar .parcentage_div {
  display: flex;
  justify-content: space-between;
}

.mini_progress_bar h5 {
  align-self: center;
}

.BasicColumnChart {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.BasicColumnChart .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BasicColumnChart .header div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.BasicColumnChart .header div p {
  padding: 1px 4px;
  font-size: 11px;
  background-color: rgb(59, 59, 59);
  border-radius: 7px;
  color: white;
}

/* Age Card Start */

.age-chart {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}
.age-chart h3 {
  color: #444e5a;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

/* Age Card End */

.Audience_div h2 {
  color: #444e5a;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

/* Gender Card Start */

.donut-chart {
  padding: 20px 20px 7px 20px;
  background-color: white;
  border-radius: 10px;
  min-height: 390px;
}

.donut-chart h3 {
  color: #444e5a;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.donut-chart .gender-div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f1f1f1;

  padding: 13px 0;
}

.donut-chart .gender-div .name{
  min-width: 50%;
}
.donut-chart .gender-div div {
  display: flex;
  gap: 10px;
  align-items: baseline;
}

.donut-chart .gender-div p {
  color: #444e5a;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

/* Gender Card End */

.family_div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 20px 20px 20px;
  background-color: white;
  border-radius: 10px;
  min-height: 390px;
}
.family_div .mostly{
  margin-top: 1rem;
}
.family_div .mostly h4{
  text-transform: uppercase;
  font-size: 12px;
}

.family_div .parent_div {
  display: flex;
  flex-direction: column;
}

.family_div .parent_div div {
  display: flex;
  justify-content: space-between;
}

.family_div .parent_div div div {
  display: flex;
  gap: 30px;
}

.country-div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  min-height: 390px;
}

.country-div .header {
  display: flex;
  justify-content: space-between;
}
.country-div .header h3 {
  color: #444e5a;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.country-div .header div {
  display: flex;
  gap: 10px;
}
.country-div .header .disable {
  color: gray;
  cursor: not-allowed;
}

.country-div .down_icon {
  padding: 1px;
  font-size: 20px;
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
}

.country-div .icon {
  padding: 1px;
  font-size: 20px;
  border: 1px solid;
  border-radius: 10px;
  cursor: pointer;
}

.country-div .flag-div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 20px;
  color: #444e5a;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.country-div .flag-div:last-child {
  border-bottom: 0;
}
.country-div .flag-div img {
  height: 20px;
}

.country-div .flag-div div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.country-div .flag-div .values {
  gap: 20px;
}

.country-div .flag-div .values .users {
  display: flex;
  align-items: center;
  gap: 3px;
}
.country-div .flag-div .values .user-icon {
  font-size: 12px;
  margin-bottom: 2px;
}

.occupation-div {
  min-height: 400px;
}

.occupation-div .flag-div .values{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
}

.badge_div {
  width: 10px;
  height: 10px;
  background-color: #4ebcfd;
  border-radius: 10px;
}

.gap_div {
  gap: 100px !important;
}

.likes_div {
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: 20px 20px 20px 20px;
  background-color: white;
  border-radius: 10px;
  min-height: 400px;
}

.likes_div .header {
  display: flex;
  justify-content: space-between;
}

.likes_div .header div {
  display: flex;
  gap: 10px;
}

.likes_div .down_icon {
  padding: 1px;
  font-size: 20px;
  border: 1px solid;
  border-radius: 5px;
  cursor: pointer;
}

.likes_div .icon {
  padding: 1px;
  font-size: 20px;
  border: 1px solid;
  border-radius: 10px;
  cursor: pointer;
}

.likes_div .progress_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.likes_div .progress_div div {
  display: flex;
  gap: 100px;
}

.brand_div {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.brand_div div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.brand_div img {
  width: 60px;
  height: 30px;
}
.Audience_div .active {
  background-color: black;
  color: white;
}

@media screen and (max-width: 425px) {
  .gap_div {
    gap: 30px !important;
  }
}

@media screen and (max-width: 425px) {
  .chart_parent .Audience_div {
    flex-direction: column;
    align-items: flex-end;
  }

  .chart_parent .social_icon {
    padding: 4px;
    font-size: 30px;
  }
  .Audience_div h2 {
    font-size: 16px;
  }
}
