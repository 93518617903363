.timelineChart_container {
  padding: 20px;
  background-color: white;
}
.timelineChart_container .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem !important;
}
.timelineChart_container .header p {
  font-size: 16px;
  font-weight: 600;
}
.timelineChart_container .header div {
  display: flex;
  align-items: center;
  gap: 10px;
}
