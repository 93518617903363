@font-face {
  font-family: "avenir";
  src: url("../../../assets/Fonts/AvenirLTStd-Book.otf");
}
.influencerModal .ant-drawer-content {
  background-color: var(--background);
}
.campaign-influencer-modal .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 10px; */
}
.campaign-influencer-modal .header h3 {
  font-size: 18px;
  color: rgb(105, 105, 105);
  font-weight: 600;
}

.campaign-influencer-modal .header .info {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.campaign-influencer-modal .header .info .icon {
  height: 32px;
  width: 32px;
  padding: 7px;
  border-radius: 4px;
  color: rgb(99, 99, 99);
  cursor: pointer;
  background-color: rgb(241, 241, 241);
}

.campaign-influencer-modal .header .info .icon:hover {
  color: var(--primary);
}

.campaign-influencer-modal .card {
  height: max-content;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-top: 1rem;
}
.campaign-influencer-modal .card .left-side {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.campaign-influencer-modal .card .left-side img {
  width: 110px;
  height: 110px;
  border-radius: 10px;
}

.campaign-influencer-modal .card .middle {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}
.campaign-influencer-modal .card .middle .social {
  display: flex;
  align-items: center;
  gap: 8px;
}
.campaign-influencer-modal .card .middle .social .icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  padding: 2px;
  color: rgb(121, 121, 121);
  cursor: pointer;
  background-color: blanchedalmond;
}
.campaign-influencer-modal .card .middle .social .disable {
  background-color: rgba(180, 180, 180, 0.212);
  color: rgb(204, 204, 204);
  cursor: default;
}
.campaign-influencer-modal .card .middle .name {
  font-size: 14px;
  margin-top: 4px;
  font-weight: 600;
}
.campaign-influencer-modal .card .middle .desc {
  width: 99%;
  font-size: 11px;
}

.campaign-influencer-modal .card .right-side {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  border-left: 3px solid rgb(219, 219, 219);
  padding-left: 10px;
  padding-top: 5px;
}
.campaign-influencer-modal .card .right-side .list {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  font-size: 12px;
}
.campaign-influencer-modal .card .right-side .list .icon {
  font-size: 14px;
  color: gray;
}
.campaign-influencer-modal .card .right-side .list .count {
  /* margin-left: 2rem; */
  float: right;
}

.campaign-influencer-modal .collapse-container {
  margin-top: 1rem;
}

.campaign-influencer-modal
  .collapse-container
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  font-size: 15px !important;
  font-weight: 600;
  font-family: "avenir" !important;
  background-color: white !important;
  /* border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important; */
  border-radius: 6px !important;
}

.campaign-influencer-modal .collapse-container .extra-icon {
  font-size: 20px !important;
  color: gray;
}

.campaign-influencer-modal .ant-collapse {
  border: 0;
  background-color: transparent;
}
.campaign-influencer-modal .ant-collapse > .ant-collapse-item {
  margin-top: 0.5rem;
}

.campaign-influencer-modal
  .collapse-container
  .ant-collapse
  > .ant-collapse-item-active
  > .ant-collapse-header {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.diliverables-container .item {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 18px;
  margin-top: 10px;
}

.diliverables-container .item .left {
  display: flex;
}

.diliverables-container .item .left .main-icon {
  height: 40px;
  width: 40px;
  border-radius: 6px;
  background-color: rgb(241, 241, 241);
  color: gray;
  padding: 5px;
  margin-right: 10px;
}
.diliverables-container .item .left .info .line {
  display: flex;
  align-items: center;
  gap: 10px;
}
.diliverables-container .item .left .info .line p {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: gray;
}
.diliverables-container .item .left .info .line .icon {
  color: gray;
}
.diliverables-container .item .edit {
  height: 30px;
  width: 30px;
  border-radius: 6px;
  color: gray;
  padding: 5px;
  cursor: pointer;
}
.diliverables-container .item .edit:hover {
  background-color: rgb(241, 241, 241);
}

.other-fee-container .action-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.other-fee-container .ant-table .ant-table-expanded-row-fixed {
  min-height: 140px !important;
}
