.paid-partners-container .tabs-container {
  margin-top: 1rem;
}

/* Partner CSS */
.partner-container .analtics-boxes {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.partner-container .analtics-boxes .box {
  background-color: white;
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: 6px;
}

.partner-container .analtics-boxes .box span {
  font-size: 24px;
  font-weight: 500;
  color: rgb(75, 75, 75);
}

.partner-container .analtics-boxes .box p {
  font-size: 10px;
  color: gray;
  text-transform: uppercase;
}

@media (max-width: 1030px) {
  .partner-container .analtics-boxes .box span {
    font-size: 20px;
  }
}
