.faceBookButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #2374f2;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 600;
  border: 0;
  padding: 3px 10px;
  color: white;
  margin-top: 10px;
  cursor: pointer;
}
.fbicon {
  fill: white;
  font-size: 26px;
  margin-bottom: 2px;
}

.Profile-container .main {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Profile-container .main h2 {
  font-size: 30px;
  font-weight: 600;
}
.Profile-container .main p {
  margin-bottom: 1rem;
  font-weight: 600;
  color: gray;
}

.tiktonButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: black;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 600;
  border: 0;
  padding: 3px 10px;
  color: white;
  margin-top: 10px;
  cursor: pointer;
}

.Profile-container .main .social-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
  margin-top: 2rem;
}
.Profile-container .main .social-container .account {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Profile-container .main .social-container .account .icon {
  cursor: pointer;
  font-size: 40px;
}

.Profile-container .main .social-container .account .facebook {
  color: #2374f2;
}

.Profile-container .main .social-container .account .twitter {
  color: #4e91fd;
}

.Profile-container .main .social-container .account .snapchat {
  color: #ebfd4e;
}

.Profile-container .main .social-container .account p {
  font-size: 10px;
  margin-top: 0.5rem;
  color: gray;
}

.facebook-modal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 150px;
}

.facebook-modal .pages-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.facebook-modal .connected {
  font-size: 24px;
  font-weight: 600;
}
