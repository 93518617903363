.tasks-container .task-list {
  width: 100%;
  margin-top: 1rem;
}
.tasks-container .task-list .heading {
  margin-bottom: 1.4rem;
  font-size: 16px;
  color: gray;
  margin-top: 2rem;
}
.tasks-container .task-list .task {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 20px;
  background-color: white;
  border-radius: 4px;
  margin-top: 5px;
  padding-left: 2rem;
}

.tasks-container .task-list .task .left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.tasks-container .task-list .task .left .info .todo {
  font-size: 16px;
  font-family: "Nunito", sans-serif;

  /* font-weight: 600; */
}
.tasks-container .task-list .task .left .info .influencer {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 14px;
  color: gray;
  width: max-content;
  font-family: "Nunito", sans-serif;
}
.tasks-container .task-list .task .left .info .influencer:hover {
  color: var(--primary);
  cursor: pointer;
}
.tasks-container .task-list .task .left .info .influencer .icon {
  margin-bottom: 2px;
}
.tasks-container .task-list .task .right .date {
  padding: 10px 20px;
  background-color: rgb(251, 179, 145);
  font-size: 14px;
  border-radius: 4px;
  color: rgb(70, 70, 70);
}
