.inf-connect-container{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    min-height: 100vh;
}
.inf-connect-container .header{
    display: flex;
    align-items: center;
    height: 90px;
    background-color: white;
    padding-left: 2rem;
    width: 100%;
}

.inf-connect-container .header img{
    height: 60px;
}

.inf-connect-container .main{
    width: 72%;
    background-color: white;
    min-height: 60vh;
    margin-top: 7rem;
    border-radius: 10px;
    padding: 100px 20px 20px 20px;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.inf-connect-container .main .profile-image{
    position: absolute;
    top: -70px;
    padding: 10px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-color: white;
}
.inf-connect-container .main .profile-image img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.inf-connect-container .main h1{
    font-weight: 600;
    font-size: 36px;
    line-height: 1.25;
    letter-spacing: -.3px;
}
.inf-connect-container .main p{
    font-weight: 500;
    font-size: 24px;
    line-height: 1.25;
    width: 80%;
    color: #444e5a;
    font-family: helvetica neue,helvetica,sans-serif;
}

.connect-div{
    width: 72%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 30px auto;
    /* padding: 30px; */
    padding-bottom: 30px;
}
.connect-div .circle{
    background-color: rgb(170, 27, 207);
    width: 40px;
    height: 40px;
    margin: 0 15px;
    border-radius: 100%;
    font-size: 24px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.connect-div .connect-section{
    background-color: #f2f7fb;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.connect-div .inner-div{
    width: 50%;
    min-height: 100px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    justify-content: space-between;
}
.connect-div .left{
    display: flex;
    align-items: center;
}
.connect-div .left h2{
    margin-bottom: 0;
    font-weight: bold;
}
