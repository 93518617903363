.influencer-info .MainInfoParenDiv {
  background-color: white;
  border-radius: 5px;
}

.influencer-info .InfoDetailsDiv {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(240, 240, 240);
  cursor: pointer;
}
.influencer-info .InfoDetailsDiv .input {
  width: 300px;
}
.edit-div .action-div {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 10px;
}
.influencer-info .InfoDetailsDiv p {
  font-size: 13px;
  color: rgb(136, 136, 136);
  font-weight: 600;
  width: 200px;
}
.influencer-info .InfoDetailsDiv .categories {
  display: flex;
  align-items: center;
  gap: 8px;
}
.influencer-info .InfoDetailsDiv .categories p {
  width: max-content;
  border-radius: 3px;
  border: 1px solid rgb(233, 233, 233);
  padding: 3px 8px;
}
.influencer-info .InfoDetailsDiv .icon {
  color: gray;
  border-radius: 4px;
  padding: 4px;
  height: 30px;
  width: 30px;
  border: 1px solid rgb(201, 201, 201);
  opacity: 0;
}
.influencer-info .InfoDetailsDiv:hover .icon {
  opacity: 100;
}

.influencer-info .taskParenDiv {
  background-color: white;
  border-radius: 5px;
  padding: 20px 20px 200px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.influencer-info .Info_Button_Div {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  color: white;
  background-image: linear-gradient(to right, #53bdfd, #62bafc);
  font-size: 15px;
  cursor: pointer;
}
.influencer-info .Info_Button_Div .Info_Icon_Text_Div p {
  font-size: 13px;
  font-weight: 600;
}
.influencer-info .Info_Button_Div .Info_Icon_Text_Div .add {
  font-size: 24px;
}
.influencer-info .Info_Icon_Text_Div {
  display: flex;
  align-items: center;
  gap: 20px;
}

.influencer-info .Info_Icon_Div {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 20px;
}

.influencer-info .NotesParenDiv {
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 1rem;
}

.influencer-info .Notes_Text_Icon_Div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.influencer-info .Notes_Icon_Div {
  display: flex;
  gap: 10px;
}

.influencer-info .Notes_TextArea_Main_Div {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  gap: 10px;
}

.influencer-info .Notes_Circle_Div {
  background: #edecf1;
  border-radius: 100px;
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.influencer-info .filter-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: white;
  padding: 2px 10px;
  border-radius: 4px;
  height: 38px;
  font-size: 18px;
  color: gray;
  cursor: pointer;
  border: 1px solid rgb(182, 182, 182);
}
.influencer-info .filter-div:hover {
  border-color: var(--primary);
  color: var(--primary);
}

.influencer-info .Notes_Comments_div {
  display: flex;
  gap: 10px;
  align-items: center;
  border-top: 1px solid rgb(230, 230, 230);
  padding: 1.6rem 0;
  justify-content: space-between;
}

.influencer-info .Notes_Comments_div > img {
  width: 38px;
  height: 38px;
  border-radius: 100px;
}
