.brandBuzz-container .filter-bar .left-side .datePicker {
  border-radius: 4px;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  cursor: pointer;
}
.brandBuzz-container .filter-bar .left-side .datePicker:hover {
  background-color: var(--primaryHover) !important;
}
.brandBuzz-container
  .filter-bar
  .left-side
  .datePicker
  .ant-picker-input
  > input,
.brandBuzz-container
  .filter-bar
  .left-side
  .datePicker
  .ant-picker-input
  > input::placeholder,
.brandBuzz-container .filter-bar .left-side .datePicker .ant-picker-suffix {
  color: white !important;
  cursor: pointer;
}

.ant-dropdown-menu-item.sidebar-menu{
  min-width: 100px !important;
}
.ant-dropdown-menu-item.sidebar-menu:hover{
  background-color: #f5f5f5 !important;
  color: var(--primary) !important;
}
.ant-dropdown-menu-item.sidebar-menu .drop-down-icon{
  font-size: 18px !important;
   color: #494949 !important;
}
.ant-dropdown-menu-item.sidebar-menu:hover .drop-down-icon{
  color: var(--primary) !important;
}
.clear-filter{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 2px 12px;
  border-radius: 4px;
  height: 34px;
  font-size: 14px;
  color: gray;
  cursor: pointer;
  border: 1px solid rgb(218, 218, 218);
}
.clear-filter:hover{
  color: var(--primary);
  border: 1px solid var(--primary);
}
@media (max-width: 700px) {
  .bb-card .detail {
    height: auto;
  }
}
