.groupsCard-container {
  background-color: white;
  border-radius: 6px;
  padding: 20px 14px;
  cursor: pointer;
}
.groupsCard-container:hover {
  background-color: rgb(247, 247, 247);
}
.groupsCard-container .desc {
  color: gray;
  font-size: 18px;
  width: 90%;
}
.dots-icon {
  height: 26px;
  width: 40px;
  padding: 4px;
  border-radius: 8px;
  background-color: rgb(224, 224, 224);
  float: right;
  cursor: pointer;
}
.dots-icon:hover {
  background-color: rgb(241, 241, 241);
  /* color: var(--primary); */
}
.groupsCard-container .home,
.groupsCard-container .influencers,
.groupsCard-container .name {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 18px;
  color: gray;
}
.groupsCard-container .name {
  float: right;
}
