.update-kpi-container {
  background-color: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.update-kpi-container .section {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  /* border: 1px solid var(--primary); */
  /* border-radius: 10px; */
  /* padding: 0.5rem; */
}

.update-kpi-container .section .title {
  font-size: 22px;
  font-weight: 600;
}

.update-kpi-container .section .field-container label {
  font-weight: 600;
}

.update-kpi-container .section .point-list-container {
  padding: 0 40px;
}

.update-kpi-container .section .point-list-container p {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.update-kpi-container .section .point-list-container p svg {
  cursor: pointer;
  color: var(--primary) !important;
}
