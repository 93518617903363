.mainContent {
  background-color: var(--background);
  min-height: 100vh;
  padding: 1.3rem 2px;
}

.public-main-content {
  background-color: var(--background);
  min-height: 100vh;
  padding: 1.3rem 2px;
}

.ant-layout-header {
  background-color: var(--background) !important;
  min-height: 12vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 6;
  padding: 0 20px;
}

.v-layout {
  display: block;
}
.m-layout {
  display: none;
}
.pub-v-layout {
  display: block;
}
.pub-m-layout {
  display: none;
}

@media (max-width: 900px) {
  .v-layout {
    display: none;
  }
  .m-layout {
    display: block;
  }
  .pub-v-layout {
    display: none;
  }
  .pub-m-layout {
    display: block;
  }
}
