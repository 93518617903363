.network-row-card {
  height: max-content;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}
.network-row-card .left-side {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.network-row-card .left-side .image-div {
  position: relative;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  cursor: pointer;
}
.network-row-card .left-side .image-div img {
  width: 100%;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.network-row-card .left-side .image-div .options {
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 10px;
  left: 10px;
  display: none;
}
.network-row-card:hover .left-side .image-div .options {
  display: flex;
}
.network-row-card .left-side .image-div .options .icon {
  height: 32px;
  width: 32px;
  padding: 7px;
  border-radius: 4px;
  background-color: white;
  color: rgb(99, 99, 99);
  cursor: pointer;
}
.network-row-card .left-side .image-div .options .icon:hover {
  color: var(--primary);
  background-color: rgb(241, 241, 241);
}
.network-row-card .left-side .image-div .aligned-div{
  position: absolute;
  top: 10px;
  right: 10px;
}
.network-row-card .left-side .image-div .aligned-div .icon{
  font-size: 26px;
  color: white;
  border-radius: 50%;
}
.network-row-card .left-side .image-div .aligned-div .aligned{
  background-color: rgb(54 130 127);
}
.network-row-card .left-side .image-div .aligned-div .notaligned{
  background-color: rgb(175 27 63);
}

.network-row-card .middle {
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  padding-left: 2rem;
}
.network-row-card .middle .social {
  display: flex;
  align-items: center;
  gap: 8px;
}
.network-row-card .middle .social .icon {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  padding: 6px;
  color: rgb(121, 121, 121);
  cursor: pointer;
  background-color: blanchedalmond;
}
.network-row-card .middle .social .disable {
  background-color: rgba(180, 180, 180, 0.212);
  color: rgb(204, 204, 204);
  cursor: default;
}

.network-row-card .middle .categories {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 6px;
}
.network-row-card .middle .categories span {
  padding: 2px 7px;
  border-radius: 5px;
  font-size: 11px;
  font-weight: 600;
  color: #fc7548;
  border: 1px solid #fc7548;
}
.network-row-card .middle .address {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  margin-top: 1rem;
}
.network-row-card .middle .address .icon {
  color: gray;
  font-size: 18px;
  margin-left: -2px;
}
.network-row-card .middle .campaign {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  cursor: pointer;
  margin-top: 6px;
}
.network-row-card .middle .campaign:hover,
.network-row-card .middle .campaign:hover .icon {
  color: var(--primary);
}
.network-row-card .middle .campaign .icon {
  color: gray;
  font-size: 16px;
  margin-bottom: 4px;
}
.network-row-card .middle .desc {
  width: 99%;
}
.network-row-card .middle .reach {
  color: white;
  background-color: var(--primary);
  padding: 4px 12px;
  font-size: 14px;
  height: max-content;
  border-radius: 4px;
  position: absolute;
  top: 16px;
  right: 10px;
}
.network-row-card .right-side {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-top: 1rem;
  border-left: 3px solid rgb(219, 219, 219);
  padding-left: 2rem;
}
.network-row-card .right-side .list {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.network-row-card .right-side .list .icon {
  font-size: 18px;
  color: gray;
}
.network-row-card .right-side .list .count {
  /* margin-left: 2rem; */
  float: right;
}

@media (max-width: 700px) {
  .network-row-card .right-side {
    border: 0;
    padding-bottom: 1rem;
    padding-left: 1rem;
  }
  .network-row-card .middle {
    padding-left: 1rem;
  }
  .network-row-card .middle .social {
    margin-bottom: 6px;
  }
  .network-row-card .middle .social .icon {
    width: 24px;
    height: 24px;
    padding: 4px;
  }
  .network-row-card .middle .reach {
    padding: 4px 6px;
    font-size: 10px;
  }
  .network-row-card .left-side .image-div {
    border-bottom-left-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .network-row-card .left-side .image-div img {
    border-bottom-left-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
