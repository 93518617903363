.notes-container .notes-collapse {
  margin-top: 2rem;
}

.notes-container .note {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0.5rem 1.6rem;
  width: 100%;
}

.notes-container .note .left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.notes-container .note .left .avatar {
  background-color: blanchedalmond;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 18px;
  border-radius: 50%;
}
.notes-container .note .left .info .todo {
  font-size: 16px;
  font-family: "Nunito", sans-serif;
}
.notes-container .note .left .info .todo span {
  font-weight: bold;
}
.notes-container .note .left .info .influencer {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 15px;
  color: rgb(104, 104, 104);
  width: max-content;
  font-family: "Nunito", sans-serif;
}
.notes-container .note .left .info .influencer span:hover {
  color: var(--primary);
  cursor: pointer;
}
.notes-container .note .left .info .influencer span {
  font-weight: bold;
}
.notes-container .note .left .info .influencer .icon {
  margin-bottom: 2px;
}
.notes-container .note .right .date {
  font-size: 16px;
  border-radius: 4px;
  color: rgb(70, 70, 70);
  display: flex;
  align-items: center;
  gap: 8px;
}
.notes-container .note .right .date .icon {
  font-size: 24px;
  color: gray;
}

.ant-collapse {
  border-radius: 4px !important;
}

.notes-collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: white !important;
  border-radius: 4px !important;
}

.notes-collapse .ant-collapse-content {
  border: 0 !important;
}

.notes-container .note-panel {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 1.6rem;
}
