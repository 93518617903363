.summary_container{
  width: 100%;
  overflow-x: auto;
}
.summary_container .heading {
  display: flex;
  justify-content: space-evenly;
  background-color: white;
  padding: 20px;
  min-width: 1200px;
}
.summary_container .heading_icon {
  display: flex;
  align-items: center;
  gap: 10px;
}
.heading_icon h1 {
  font-size: 24px;
}
.col_div p {
  font-size: 10px;
}
.summary_container .heading .col_div {
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.summary_container .col_div:not(:last-child) {
  border-right: 1px solid #d6d5d5;
}

@media screen and (max-width: 425px) {
  .heading_icon h1 {
    font-size: 20px;
  }
}
