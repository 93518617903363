.mainHeader {
  background-color: white !important;
  min-height: 60px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding-right: 1rem;
}
.mainHeader .right {
  display: flex;
  align-items: center;
  /* gap: 4px; */
}
.mainHeader .name {
  font-size: 16px;
  color: gray;
  cursor: pointer;
  margin-left: 6px;
}
.mainHeader .icon {
  font-size: 20px;
  color: gray;
  cursor: pointer;
}
.mainHeader .icon:hover {
  color: var(--primary);
}
.mainHeader .profile-pic {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-left: 0.7rem;
  cursor: pointer;
}
.mainHeader .logout {
  color: var(--primary);
  font-size: 20px;
  margin-left: 1rem;
}
.mainHeader .search-input {
  width: 200px;
  border-radius: 6px;
  height: 30px !important;
}
.mainHeader .search-input > input.ant-input {
  padding-bottom: 10px !important;
}

.pub-header{
  background-color: white !important;
  min-height: 60px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding-right: 1rem;
}

.pub-header{
  display: flex;
  align-items: center;
  height: 90px;
  background-color: white;
  padding-left: 2rem;
  width: 100%;
}

.pub-header img{
  height: 60px;
}

.brand-select-container{
  border: 1px solid #cecece !important;
  color: gray !important;
  height: 36px;
  display: flex;
  align-items: center;
  /* gap: 10px; */
  padding: 4px 10px;
  border-radius: 6px;
  padding-right: 0px;
  font-size: 15px;
}
.brand-select-header .ant-select-selection-item{
  font-weight: 700;
}
.brand-select-header:not(.ant-select-customize-input) .ant-select-selector{
  /* color: var(--primary) !important; */
}
.brand-select-header .ant-select-arrow{
  /* color: var(--primary) !important; */
}