.expand-container .expand-header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.expand-container .expand-header .title {
  font-size: 10px;
  margin-bottom: 10px;
  text-align: right;
}

.expand-container .expand-header h1 {
  font-size: 20px;
  font-weight: bold;
}

.expand-container .progress-div{
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}
.expand-container .progress-div p{
  font-size: 12px;
}
