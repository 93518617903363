.leaderboard-container .header {
  padding: 20px;
  font-weight: bold;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}
.leaderboard-container .header div {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdbe1;
  padding: 7px;
  border-radius: 4px;
}

.leaderboard-container .dataStyle {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  justify-content: center;
}

.leaderboard-container .icons-header {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.leaderboard-container .icons-header p {
  font-size: 13px;
  color: #888888;
  color: #444e5a;
}
.leaderboard-container .icons-header div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.leaderboard-container .icons-header div .icon{
  color: #888888;
}
.onHover:hover {
  cursor: pointer;
  background-color: #f7f7f7;
}
.leaderboard-container .ant-progress-bg {
  height: 4px !important;
}
.leaderboard-container .ant-spin-nested-loading > div > .ant-spin{
  max-height: 100% !important;
}