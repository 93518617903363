.login-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.login-container .left-side {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
}
.login-container .left-side img {
  width: 400px;
}
.login-container .right-side {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.login-container .right-side .main {
  width: 400px;
}
.login-container .right-side .main .heading {
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 30px;
  color: #4e4e4e;
}
.phone-logo {
  display: none;
}

@media (max-width: 700px) {
  .login-container .left-side {
    display: none;
  }
  .login-container .phone-logo {
    height: 30px;
    position: absolute;
    top: 1rem;
    left: 0.9rem;
    z-index: 10;
    display: block;
  }
  .login-container .right-side {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  .login-container .right-side .main {
    width: 85%;
  }
  .login-container .right-side .main .heading {
    font-size: 24px;
  }
}
