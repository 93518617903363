.campaign-efficiency .profile-info {
  display: flex;
  align-items: center;
  gap: 8px;
}
.campaign-efficiency .profile-info img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}
.campaign-efficiency .profile-info span {
  font-weight: 600;
}

.campaign-efficiency .box {
  background-color: white;
  padding: 40px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: 6px;
  width: 100%;
  padding-right: 1.3rem;
}

.campaign-efficiency .box span {
  font-size: 24px;
  font-weight: 500;
  color: rgb(75, 75, 75);
}

.campaign-efficiency .box p {
  font-size: 12px;
  color: gray;
  text-transform: uppercase;
}
