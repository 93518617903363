@font-face {
  font-family: "avenir";
  src: url("../../assets/Fonts/AvenirLTStd-Book.otf");
}

.foot-print-container .platform {
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  text-align: right;
  margin: 20px 0;
}
.foot-print-container .platform .link {
  color: #cad1dd !important;
  font-size: 13px;
  margin: 0;
  line-height: 10px;
}
.foot-print-container .platform .link:hover {
  text-decoration: underline;
}
.foot-print-container .disable {
  background-color: rgba(180, 180, 180, 0.212);
}
.foot-print-container .disable p,
.foot-print-container .disable h1,
.foot-print-container .disable h2,
.foot-print-container .disable .icon {
  color: gray !important;
}
.foot-print-container .platform .info {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: left !important;
}

.foot-print-container .platform .info .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: rgb(204, 204, 204);
  padding: 8px;
  width: 44px;
  height: 44px;
  border-radius: 100px;
  font-size: 20px;
}


.foot-print-container .FootPrint_Col .Tooltip_Style {
  /* margin-top: 1rem; */
  cursor: pointer;
  margin-bottom: 1rem;
}

.foot-print-container .FootPrint_Col .Tooltip_Style p {
  width: 80%;
  float: right;
}

.foot-print-container .FootPrint_Col p {
  font-size: 10px;
  font-weight: 600;
  font-family: "avenir";
}
.foot-print-container .FootPrint_Col h1 {
  font-size: 18px;
  font-family: "avenir";
}
.foot-print-container h2 {
  width: max-content !important;
}
.foot-print-container .FootPrint_Col .heading {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: flex-end;
  position: relative;
}
.foot-print-container .FootPrint_Col .heading .icon {
  font-size: 23px;
  color: gray;
  position: absolute;
  right: -20px;
}

@media (max-width: 1199px) {
  .foot-print-container .FootPrint_Col {
    text-align: left;
    align-items: center;
    margin-top: 1rem;
  }
  .foot-print-container .FootPrint_Col .heading {
    justify-content: flex-start;
  }
  .foot-print-container .FootPrint_Col .heading .icon {
    right: 0px;
    position: relative;
  }
}
