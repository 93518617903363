.campaigns-container .main {
  margin-top: 2rem;
}

.create-campaigns-container .main {
  background-color: white;
  padding: 2rem;
  padding-top: 1rem;
}

.ant-select-multiple .ant-select-selection-item-content {
  color: gray !important;
}

.create-campaigns-container .main .heading {
  margin-bottom: 1rem;
}

.category-influencers-collapse{
  border: 1px solid rgb(233, 233, 233) !important;
}

.category-influencers-collapse .ant-collapse-item{
  border-bottom: 1px solid rgb(233, 233, 233) !important;
}

.category-influencers-collapse .ant-collapse-header {
  background: white !important;
  font-size: 14px !important;
  align-items: center !important;
}

.category-influencers-collapse .ant-collapse-content-box {
  background: #f1f1f1;
  max-height: 400px;
  overflow-y: auto;
}

@media (max-width: 580px) {
  .category-influencers-collapse .ant-collapse-content-box {
    max-height: 500px;
  }
}
