.ant-btn-link {
  font-weight: bold !important;
  padding: 4px 0;
}
.ant-btn-primary {
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 2px;
  height: 40px;
}
.ant-input,
.ant-picker {
  height: 37px;
  border-radius: 10px;
}
.ant-form-item-label label {
  letter-spacing: 1px;
}
.ant-checkbox-wrapper {
  padding: 5px 0;
}
.ant-divider-vertical {
  height: auto;
  /* border-left: 2px solid rgba(0, 0, 0, 0.06); */
  border-left: 1px solid rgba(138, 27, 106, 0.5);
}

.ant-pagination-total-text {
  display: flex;
  margin-right: auto;
}

.ant-modal-content {
  background-color: var(--cardColor) !important;
}
.ant-modal-close-x {
  color: var(--text) !important;
}
.ant-modal-header {
  background-color: var(--cardColor) !important;
}
.ant-modal-title {
  color: var(--text) !important;
}
.ant-form-item-label label {
  color: var(--text) !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 20px;
  color: var(--primary-color);
}
.ant-input {
  background-color: transparent !important;
  border: 1.6px solid var(--borderLight);
  border-radius: 4px;
  color: var(--text) !important;
  height: 40px !important;
}
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background-color: transparent !important;
}

.ant-tabs-tab-btn {
  color: gray;
  font-family: 'avenir' !important;
}

.ant-select-arrow,
.ant-select-selection-placeholder,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: gray !important;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: white !important;
  background-color: white !important;
}
.ant-tooltip-inner {
  color: gray !important;
  text-transform: uppercase;
  background-color: white !important;
  padding: 10px !important;
  border-radius: 8px !important;
  width: max-content;
}

.chart-skeleton {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin: 1rem 0;
}
.chart-skeleton .ant-skeleton-element{
  width: 100% !important;
}
.ant-spin-nested-loading > div > .ant-spin{
  background-color: white;
}