.sidebar-menu {
  background-color: white !important;
  color: black !important;
  font-size: 18px;
}
.sidebar-menu a{
  color: black !important;
  font-size: 16px;
  text-decoration: none;
}
.sidebar-menu svg {
  font-size: 20px !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-right: 4px solid var(--primary) !important;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  span a,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  span,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  svg {
  color: var(--primary) !important;
}
.ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  color: var(--text);
  font-size: 16px;
}
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
  font-size: 16px;
}
.ant-menu-submenu-title .ant-menu-item-icon {
  color: var(--primary) !important;
  font-size: 20px;
}
.menu-icon {
  color: var(--primary) !important;
  font-size: 20px;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow::before {
  background-color: var(--text) !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: var(--sidebar) !important;
}
