.BrandSummary_Container .influencers-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 5px;
}
.influencers-container .header {
  padding: 10px 40px 10px 15px;
  border-radius: 5px;
  background-color: white;
  margin-bottom: 8px !important;
}
.influencers-container .header p {
  font-size: 12px;
  font-weight: 600;
}
.influencers-container .profile {
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  gap: 10px;
  margin-bottom: 6px;
}
.influencers-container img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.influencers-container .info {
  display: flex;
  justify-content: space-between;
}
.influencers-container .info h3 {
  font-size: 13px;
  font-weight: 600;
}
.influencers-container .info div {
  display: flex;
  gap: 5px;
}
.influencers-container .info div .icon {
  color: gray;
  font-size: 18px;
}
.influencers-container .profile .details {
  flex: 9;
}

.influencers-container .ant-progress-bg {
  height: 6px !important;
}
