.discovery-container .main {
  padding: 2rem 0;
  border-radius: 4px;
  margin-top: 1rem;
}

.discovery-container .main .filter-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: white;
  padding: 2px 16px;
  border-radius: 4px;
  height: 38px;
  font-size: 18px;
  color: gray;
  cursor: pointer;
  border: 1px solid rgb(182, 182, 182);
  width: 140px;
}
.discovery-container .main .filter-div:hover {
  border-color: var(--primary);
  color: var(--primary);
}
.discovery-container .main .right-side {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.6rem;
}

.discovery-container .main .more-icon {
  width: 38px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid rgb(206, 206, 206);
  padding: 6px;
  color: gray;
  cursor: pointer;
}
.discovery-container .main .more-icon:hover {
  border-color: var(--primary);
}

.discovery-container .tabs-container {
  margin-top: 1rem;
}

.discovery-container .main .ant-input {
  background-color: white !important;
}
