.top_performer_Container {
  background-color: white;
  padding: 20px;
  margin: 1rem 0;
  border-radius: 6px;
}
.top_performer_Container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top_performer_Container .header h3{
  font-weight: 600;
  font-size: 16px;
}
.active-container{
  width: 100%;
  overflow: auto;
  background-color: white;
  border-radius: 6px;
}
.active-container .main{
  display: flex;
  padding: 20px;
  min-width: 1200px;
  flex-flow: unset;
  gap: 2rem;
}
@media screen and (max-width: 425px) {
  .top_performer_Container .header {
    flex-direction: column;
    gap: 20px;
  }
}
