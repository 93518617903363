.campaign-social-performance .analtics-boxes {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  overflow-x: auto;
}
.campaign-social-performance .analtics-boxes .scroll-box{
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 120%;

}
.campaign-social-performance .analtics-boxes .scroll-box .box {
  background-color: white;
  padding: 25px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: 6px;
  min-width: 150px;
  width: 12%;
}

.campaign-social-performance .analtics-boxes .scroll-box .box span {
  font-size: 30px;
  font-weight: 500;
  color: rgb(75, 75, 75);
}

.campaign-social-performance .analtics-boxes .scroll-box .box p {
  font-size: 10px;
  color: gray;
  text-transform: uppercase;
}

.campaign-social-performance .chart-div {
  background-color: white;
  padding: 1rem;
  border-radius: 4px;
  padding-top: 2rem;
  margin-bottom: 1rem;
}

.campaign-social-performance .chart-div .header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.campaign-social-performance .chart-div .header .right {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.campaign-social-performance .chart-div .header h2 {
  font-size: 22px;
}

.campaign-social-performance .chart-div .chart {
  padding: 1rem 0.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.campaign-social-performance .table-div {
  padding: 2rem 1rem;
  border-radius: 4px;
  background-color: white;
}

.campaign-social-performance .tab-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  width: 100%;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: -3px;
  padding-top: 2rem;
}

.campaign-social-performance .table-div .profile-info {
  display: flex;
  align-items: center;
  gap: 8px;
}
.campaign-social-performance .table-div .profile-info img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}
.campaign-social-performance .table-div .profile-info span {
  font-weight: 600;
}

.campaign-social-performance .table-div .influncer-table-div {
  display: flex;
  align-items: center;
  gap: 6px;
}

.campaign-social-performance .table-div .influncer-table-div span {
  font-size: 16px;
  color: rgb(97, 97, 97);
  font-weight: 600;
}
.campaign-social-performance .table-div .influncer-table-div .icon {
  font-size: 18px;
  color: rgb(97, 97, 97);
  margin-bottom: 4px;
}
