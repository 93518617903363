.create-report-container {
  display: flex;
  justify-content: center;
}

.create-report-container .main {
  border-radius: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 45px -2px rgb(68 78 90 / 5%);
  margin: 30px auto;
  max-width: 700px;
  min-width: 300px;
  padding: 30px 70px 60px 70px;
  transition: max-width 250ms cubic-bezier(0.485, 0.001, 0.018, 1.013);
  width: 80vw;
}

.create-report-container .ant-steps-item-title {
  font-size: 10px;
  font-weight: 600;
  font-family: 'avenir';
  color: #444e5a;
}
.create-report-container .main .steps-div{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    width: 100%;
}
.create-report-container .main .steps-header{
    margin-top: 2rem;
}
.create-report-container .main .steps-header h1{
    color: #444E5A;
    font-size: 24px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 10px;
}
.create-report-container .main .steps-header h2{
    color: #c2c2c9;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.25;
    margin: 10px 0;
}
.create-report-container .main .steps-header p{
    color: #444E5A;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0.1px;
    line-height: 1.5;
}

.create-report-container .button-div{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 1rem;
}
.create-report-container textarea.ant-input{
    height: auto !important;
}
.create-report-container .influencer-selected{
    background-color: #FFFFFF;
    border: 1px solid #eeeef2;
    border-radius: 5px;
    color: var(--primary);
    cursor: default;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.5;
    margin-right: 20px;
    min-height: 42px;
    min-width: 210px;
    padding: 11px 18px;
    position: relative;
    text-align: center;
    width: 100px;
}
.create-report-container .switch-style{
    display: flex;
    align-items: center;
    gap: 10px;
}
.create-report-container .switch-style .ant-form-item{
    margin-bottom: 0;
}