.plateform-container .header {
  background-color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}
.plateform-container .header h3 {
  font-size: 20px;
  font-weight: 600;
}
.plateform-container .header .select {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.plateform-container .header div .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dadbe0;
  font-size: 20px;
  padding: 7px;
  margin-left: 10px;
}
.plateform-container .active-container {
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 4px;
}
.plateform-container .active-container .item{
  min-width: 400px;
}
.plateform-container .active-container .item p {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 1rem;
}
.plateform-container .circle {
  background-color: white;
  padding: 20px;
  display: flex;
  gap: 10px;
}
.plateform-container .circle div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.plateform-container .circle div div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
@media screen and (max-width: 425px) {
  .plateform-container .header {
    flex-direction: column;
  }
  .plateform-container .header .select {
    margin-top: 10px;
    flex-direction: column;
    gap: 10px;
  }
  .plateform-container .circle {
    flex-direction: column;
  }
}
