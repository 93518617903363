.add-icon {
  height: 38px;
  width: 50px;
  background-color: var(--primary);
  color: white;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}
.add-icon:hover {
  background-color: var(--primaryHover);
}
.cards-container {
  margin-top: 2rem;
}

.group-container .filter-bar .left-side .searchInput {
  width: 140px;
}

.group-container .network-row-card {
  margin-bottom: 1rem;
}
