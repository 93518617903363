.campaign-content .trending-hashtags {
  background-color: white;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 4px;
}
.campaign-content .trending-hashtags h2 {
  font-size: 26px;
}
.campaign-content .trending-hashtags .main {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  flex-wrap: wrap;
  margin-top: 0.6rem;
}
.trending-hashtags .main p {
  background-color: blanchedalmond;
  padding: 2px 10px;
  border-radius: 20px;
}
.campaign-content .profile-div{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
}

.campaign-content .profile-div img{
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.campaign-content .profile-div span{
  font-size: 18px;
  font-weight: 600;
  font-family: "avenir";
  color: gray;
}
.campaign-content .back-arrow{
  width: 35px;
  height: 30px;
  padding: 5px;
  border-radius: 6px;
  color: gray;
  background-color: white;
  cursor: pointer;
  border: 2px solid rgb(212, 212, 212);
}
.campaign-content .back-arrow:hover{
  background-color: transparent;
}