.insta-auth-link-container{
    padding-bottom: 1rem;
}

.insta-auth-link-container p{
    font-weight: 600;
    font-size: 16px;
    line-height: 1.25;
    color: #444e5a;
    /* font-family: helvetica neue,helvetica,sans-serif; */
    margin-bottom: 1.6rem;
}

.insta-auth-link-container .label{
    font-weight: 600;
    font-size: 15px;
    line-height: 1.25;
    color: #444e5a;
    width: 100%;
    margin-bottom: 4px;
}