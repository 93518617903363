.network-container .filter-bar .left-side .select-all-filter {
  height: 38px;
  border-radius: 4px;
  border: 1px solid rgb(206, 206, 206);
  padding: 6px 10px;
  color: gray;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 120px;
}
.network-container .filter-bar .left-side .searchInput {
  width: 240px;
}
.network-container .filter-bar div .more-icon {
  width: 33px;
  height: 31px;
  border-radius: 4px;
  border: 1px solid rgb(206, 206, 206);
  padding: 6px;
  color: gray;
  cursor: pointer;
}
.network-container .filter-bar div .more-icon:hover {
  border-color: var(--primary);
}

.network-container .steps {
  display: flex;
  overflow: auto;
  margin-top: 1rem;
}

.network-container .steps .filter-card__item {
  background-color: #f6f6f8;
  border-radius: 6px;
  width: 30%;
  margin-right: 5px;
  padding: 24px 20px;
  position: relative;
  cursor: pointer;
}
.network-container .steps .filter-card__item:hover {
  background-color: white;
}
.network-container .steps .filter-card__item:hover::before {
  background-color: white !important;
}
.network-container .steps .filter-card__item-num {
  display: block;
  font-size: 28px;
  font-weight: 300;
  line-height: 28px;
  margin-bottom: 10px;
}

.network-container .steps .filter-card__item-label {
  display: block;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  word-break: break-word;
}
.network-container .steps .filter-card__item11 {
  background-color: #f6f6f8;
  border-radius: 6px;
  width: 30%;
  margin-right: 5px;
  padding: 24px 20px;
  position: relative;
  cursor: pointer;
}
.network-container .steps .filter-card__item11:hover {
  background-color: white;
}
.network-container .steps .filter-card__item11:hover::before {
  background-color: white !important;
}
.network-container .steps .filter-card__item::before {
  content: "";
  position: absolute;
  right: -16px;
  top: 56px;
  background-color: #f6f6f6;
  width: 25px;
  height: 26px;
  border-radius: 5px;
  border: 5px solid #e2e2e2;
  transform: translateZ(1px) rotate(40deg);
  z-index: 10;
  border-bottom: none;
  border-left: none;
}
.network-container .steps .active,
.network-container .steps .active::before {
  background-color: white !important;
}

.network-container .network-row-card {
  margin-bottom: 1rem;
}

@media (max-width: 700px) {
  .network-container .steps .filter-card__item {
    min-width: 120px;
  }
  .network-container .steps .filter-card__item11 {
    min-width: 120px;
  }
}
