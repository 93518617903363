@font-face {
  font-family: 'avenir';
  src: url('../assets/Fonts/AvenirLTStd-Book.otf');
}

* {
  /* scroll-behavior: smooth; */
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: var(--background);
}

.swal-text {
  text-align: center !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  letter-spacing: 1px;
  margin: 0;
  font-family: 'avenir';
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
  border-radius: 20px;
  cursor: pointer;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  cursor: pointer;

  /* border-radius: 20px; */
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ef5b29dc;
  border-radius: 20px;
  cursor: pointer;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
  cursor: pointer;
}

.general-header {
  background-color: #fff;
  box-shadow: var(--boxShadow);
  padding: 17px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: fixed; */
  width: 100%;
  z-index: 100;
  top: 0;
}
.general-header .Buttons {
  width: auto;
}
.general-header .Buttons button {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.general-header .Buttons button:first-child {
  margin-right: 10px;
}
.general-header .vendor-info {
  /* display: flex;
  align-items: center; */
  width: 30%;
}
.general-header a {
  width: 50%;
}
.general-header .vendor-info p {
  margin-bottom: 0;
  margin-left: 10px;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: bold;
}

.general-header h2 {
  color: var(--secondary-color);
  margin: 0;
  letter-spacing: 2px;
  font-weight: bold;
}

.empty {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.d-flex {
  display: flex;
}
.space-between {
  justify-content: space-between;
}
.m-20 {
  margin: 20px 0;
}
.primarybtn {
  background-color: var(--primary);
  border-color: var(--primary) !important;
}

.mainHeading {
  font-size: 26px;
  font-weight: 500;
}
.subHeading {
  font-size: 12px;
  color: #444e5a;
}

/* Filter */
.filter-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 14px;
  background-color: white;
  border-radius: 4px;
  margin-top: 1rem;
  gap: 1rem;
}
.filter-bar .action-icon {
  width: 36px;
  height: 36px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid rgb(238, 238, 238);
  cursor: pointer;
  color: gray;
}
.filter-bar .action-icon:hover {
  border-color: var(--primary);
  color: var(--primary);
}
.filter-bar .delete-icon:hover {
  border-color: hsl(0, 78%, 62%) !important;
  color: hsl(0, 78%, 62%) !important;
}
.filter-bar .selected-row {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px 20px;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 4px;
  color: gray;
  font-size: 16px;
}
.filter-bar .selected-row .icon:hover {
  color: hsl(0, 78%, 62%) !important;
  cursor: pointer;
}
.filter-bar .left-side {
  display: flex;
  align-items: center;
  gap: 12px;
}
.filter-bar .searchInput {
  min-width: 240px;
  border-radius: 4px !important;
}
.filter-bar .searchInput .search-icon {
  font-size: 20px;
  color: gray;
}
.no-found {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: 16px;
  margin-top: 1rem;
  font-weight: 600;
}
.filter-bar .left-side .filter-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: transparent;
  padding: 2px 16px;
  border-radius: 4px;
  height: 38px;
  font-size: 18px;
  color: gray;
  cursor: pointer;
  border: 1px solid rgb(218, 218, 218);
}
.filter-bar .left-side .filter-div:hover {
  border-color: var(--primary);
  color: var(--primary);
}
.filter-bar .left-side .filter-div .icon {
  font-size: 20px;
}
.filter-drop-down {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  background-color: transparent;
  padding: 2px 16px;
  border-radius: 4px;
  height: 38px;
  font-size: 18px;
  color: gray;
  cursor: pointer;
  min-width: 120px;
  border: 1px solid rgb(218, 218, 218);
}
.filter-drop-down .filter-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-bar .datePicker {
  border-radius: 4px;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  cursor: pointer;
}
.filter-bar .datePicker:hover {
  background-color: var(--primaryHover) !important;
}
.filter-bar .datePicker .ant-picker-input > input,
.filter-bar .datePicker .ant-picker-input > input::placeholder,
.filter-bar .datePicker .ant-picker-suffix {
  color: white !important;
  cursor: pointer;
}

.filter-bar .right-side {
  display: flex;
  align-items: center;
  gap: 12px;
}

.dot-icon {
  padding: 4px;
  height: 30px;
  width: 40px;
  border-radius: 4px;
  cursor: pointer;
}
.dot-icon:hover {
  background-color: rgb(238, 238, 238);
}

.remove-icon {
  padding: 4px;
  height: 30px;
  width: 40px;
  border-radius: 4px;
  cursor: pointer;
  color: hsl(0, 78%, 62%);
}
.remove-icon:hover {
  color: red;
}

.date-icon {
  font-size: 16px;
  color: gray;
}
.link {
  cursor: pointer;
  color: var(--primary);
}
.link:hover {
  text-decoration: underline;
}

.infinityLoader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.infinityLoader img {
  width: 200px;
}
.dropdown-menu-icon {
  font-size: 18px;
}
.hashtag-text {
  color: var(--primary);
  cursor: pointer;
}
.hashtag-text:hover {
  text-decoration: underline;
}

.pagination-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}
.loadmore-div {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  margin-top: 2rem;
}
.loadmore-div h6 {
  cursor: pointer;
  padding: 0.2rem 1rem;
  font-size: 18px;
  color: gray;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 4px;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 10px;
}
.loadmore-div h6:hover {
  border-color: var(--primary);
  color: var(--primary);
}
.loadmore-div h6 .icon {
  font-size: 20px;
}
.loadmore-div button {
  cursor: pointer;
  padding: 0.2rem 1rem;
  font-size: 18px;
  color: gray;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 4px;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 10px;
}
.loadmore-div button:hover {
  border-color: var(--primary);
  color: var(--primary);
}
.loadmore-div button .icon {
  font-size: 20px;
}
.uploaded-div {
  min-height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px dotted rgb(219, 219, 219);
  position: relative;
}
.uploaded-div .delete {
  position: absolute;
  display: block;
  top: 10px;
  right: 10px;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  padding: 4px;
  border: 1px solid var(--primary);
  color: var(--primary);
  cursor: pointer;
}
.uploaded-div:hover .delete:hover {
  color: white;
  background-color: var(--danger);
  border: 1px solid var(--danger);
}
.uploaded-div {
  font-size: 24px;
  color: #767d85;
}

.export-csv-btn {
  height: 40px !important;
  border-radius: 4px !important;
  color: gray !important;
}

.export-csv-btn:hover {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.not-available-tag {
  background: transparent;
  font-size: 13px;
  padding: 4px 8px;
  color: #ff4d4f;
  border: 1px solid #ff4d4f;
  border-radius: 15px;
}
.upload_drop_btn .more-icon {
  width: 33px;
  height: 31px;
  border-radius: 4px;
  border: 1px solid rgb(206, 206, 206);
  padding: 6px;
  color: gray;
  cursor: pointer;
}
.link_btn_for_download{
  height: 32px;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.85);
  padding: 0 15px;
  /* border-radius: 5px; */

  display: flex;
  align-items: center;
  justify-content: center;
}
.link_btn_for_download:hover{
  cursor: pointer;
  color: var(--primary);
  border: 1px solid var(--primary);
}

/* .custom_tooltip svg{
  position: relative
}

.custom_tooltip svg p{
  position: absolute;
} */

.custom_tooltip {
  position: relative;
  display: inline-block;
}

.custom_tooltip span {
  visibility: hidden;
  width: max-content;
  background: #FFFFFF;
  color: #1C2833;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #f0f0f0;
  padding: 5px 10px;
  font-size: 15px;
  transition: all 0.3s ease;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 25px;
  left: -400px;
}

.custom_tooltip:hover span {
  visibility: visible;
}

.hash_tag{
  column-gap: 0.5rem;
  border-radius: 15px;
  /* border: 1px solid var(--primary); */
  border: 1px solid gray;
  width: max-content;
  padding: 2px 8px;

  display: flex;
  align-items: center;
}

.hash_tag svg{
  color: var(--primary);
}

.hash_tag svg:hover{
  cursor: pointer;
}
.custom_label_tag{
  font-family: 'Poppins', sans-serif;
}

.category-name-hover:hover{
  cursor: pointer;
  color: var(--primary);
}

@media(max-width: 768px){
  .custom_tooltip span{
    width: 200px;
    left: -150px;
  }
}

@media(max-width: 400px){
  .custom_tooltip span{
    bottom: -100px;
    left: 0;
  }
}

@media (max-width: 550px) {
  .general-header {
    flex-direction: column;
  }
  .general-header h2 {
    font-size: 20px;
    margin: 20px 0;
  }
  .general-header {
    align-items: flex-start;
  }
  .general-header .Buttons {
    align-items: flex-end;
    margin-left: auto;
  }
  .general-header .vendor-info p {
    font-size: 13px;
  }
  .filter-bar .left-side {
    flex-wrap: wrap;
  }
}
