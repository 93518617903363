.performersActivated_container {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* min-width: 420px; */
}
.performersActivated_container h3 {
  text-align: center;
  text-transform: uppercase;
}
.performersActivated_container .info {
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
  justify-content: space-between;
}
.performersActivated_container .info .info_name {
  /* width: 150px; */
  width: max-content;
}
.performersActivated_container .progress-div{
  width: 250px;
  display: flex;
  align-items: center;
  gap:10px;
}
.performersActivated_container .info .count {
  /* position: absolute; */
  /* left: 10px; */
  /* color: rgb(85, 85, 85); */
  /* color: white; */
  /* width: 50px; */
  /* z-index: 10; */
}


.performersActivated_container .ant-progress-bg {
  height: 40px !important;
}
.performersActivated_container .ant-progress-inner,
.ant-progress-bg {
  border-radius: 4px !important;
  cursor: pointer;
}
.performersActivated_container .ant-progress-inner {
  background-color: transparent !important;
}
.performer-tooltip .keyword{
  font-size: 12px;
  font-weight: 600;
}

.performer-tooltip .detail{
  display: flex;
  align-items: center;
  gap: 10px;
}
.performer-tooltip .detail .title{ 
   font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
}