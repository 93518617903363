.post-view-card {
  background-color: white;
  border-radius: 4px;
  width: 100%;
}
.post-view-card .header {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  gap: 1rem;
  padding: 16px;
  padding-bottom: 2rem;
}

.post-view-card .header .profile {
  position: relative;
  width: 60px;
  height: 60px;
}

.post-view-card .header .profile .img {
  width: 100%;
  height: 60px;
  border-radius: 10%;
}
.post-view-card .header .profile .icon {
  position: absolute;
  bottom: -6px;
  right: -12px;
  background-color: blanchedalmond;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 4px;
  color: rgb(121, 121, 121);
  cursor: pointer;
}

.post-view-card .header .info {
  display: flex;
  flex-direction: column;
  padding-left: 4px;
}
.post-view-card .header .info .name {
  font-size: 15px;
  font-weight: 600;
}
.post-view-card .header .info .desc {
  font-size: 12px;
  font-weight: 600;
  color: rgb(175, 175, 175);
}

.post-view-card .media {
  width: 100%;
  height: 500px;
  margin: 0 auto;
  cursor: pointer;
  position: relative;
}
.post-view-card .media:hover .more {
  width: 100%;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.233);
  z-index: 4;
}
.post-view-card .media .image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.post-view-card .detail {
  padding: 16px 0px;
  font-size: 14px;
  border-bottom: 2px solid rgb(218, 218, 218);
  color: gray;
}

.post-view-card .engage-container {
  padding: 20px 0px;
}

.post-view-card .engage-container .engage-div .tooltip {
  display: flex !important;
  align-items: center !important;
  gap: 6px !important;
}
.post-view-card .engage-container .engage-div .tooltip {
  font-size: 16px;
  color: gray;
}
.post-view-card .engage-container .engage-div .tooltip .icon {
  font-size: 20px !important;
  margin-bottom: 4px;
}
